var $class="se2--banner-full-image-carousel--slide",$name="BannerFullImageCarousel/slide",$path="app/components/BannerFullImageCarousel/partials/slide/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import {
  fixedBackgroundSelector,
  slideWithVideoSelector,
  videoSelector,
} from 'app/components/BannerFullImageCarousel/config';
import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import getPauseAfterPlayVideo from './utilities/getPauseAfterPlayVideo';
import 'app/partials/image';

export default shell.registerPartial($this, ({ mount, addEventListener, configs }) => {
  mount((element) => {
    const videoSlideElement = element.querySelector(videoSelector);
    const videoUrl = element.querySelector(fixedBackgroundSelector).dataset.videoBackground;
    const slideWithVideoElement = element.querySelector(slideWithVideoSelector);

    const primaryButtonClassName = 'se2-button-primary';
    const firstClickableButtonClassName = 'first-clickable-button';
    const buttonClassName = 'se2-button';

    element.querySelector(`.${primaryButtonClassName}`)?.classList.add(firstClickableButtonClassName);
    const firstButtonElement = element.querySelector(`.${firstClickableButtonClassName}`);

    addEventListener(element, 'click', ({ target }) => {
      if (!element.querySelector(`.${buttonClassName}`)) return;
      if (!target.classList.contains(buttonClassName) && !target.closest(`.${buttonClassName}`)) {
        setTimeout(() => { firstButtonElement?.click(); }, configs.delays.ms150);
      }
    });

    if (slideWithVideoElement) {
      const playVideoOnDesktop = () => {
        if (isMobileBreakpoint()) {
          getPauseAfterPlayVideo(videoSlideElement);
        }

        if (videoSlideElement && (videoSlideElement.src !== videoUrl)) {
          videoSlideElement.src = videoUrl;
          videoSlideElement.setAttribute('autoplay', 'autoplay');
          videoSlideElement.innerHTML = `<source src="${videoUrl}" type="video/mp4">`;
        }

        if (videoSlideElement?.paused) videoSlideElement.play();
      };

      playVideoOnDesktop();

      addEventListener(window, 'resize', playVideoOnDesktop);
    }
  });
});
